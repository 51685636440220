.empresa#modelo4{
    padding-bottom: 60px;

    .content__editable{
        @include clearfix;
        img{
            max-width: 100%;
            height: auto !important;
        }
    }

    .btn-voltar{
        margin-top: 40px;
        min-width: 200px;
        @extend .btn-danger;
    }
}