.bg__menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 1050;
	animation: fadeIn 0.5s linear;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}

.segura__contato{
	.infos__box{
		display: flex;
		align-items: center;
		justify-content: center;
		.segura__contato{
			display: flex;
			align-items: center;
			justify-content: center;
			.contato__icone{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: #3b9b40;
			}
			.segura__desc__contato{
				flex-direction: column;
				padding-left: 15px;
				.segura__infos__contato{
					display: flex;
					font-family: poppinsbold;
					font-size: 13px;
					a{
						color: #FFF;
					}
				}
				.contato__sub{
					font-family: poppinslight;
					font-size: 13px;
					color: #FFF;
				}
			}
		}
		.segura__email{
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 15px;
			.email__icone{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: #3b9b40;
			}
			.segura__desc__email{
				flex-direction: column;
				padding-left: 10px;
				.email__link{
					font-family: poppinsbold;
					font-size: 13px;
					a{
						color: #FFF;
					}
				}
				.email__sub{
					font-family: poppinslight;
					font-size: 13px;
					color: #FFF;
				}
			}
		}
	}
}

.segura__redes{
	margin-left: 180px;
	.redes__header{
		a{
			color: #FFF;
			text-decoration: none;
			font-size: 18px;
			padding-left: 10px;
		}
	}
}


@include media-breakpoint-up(lg) {
	.topo__interna{
		background-color: #1b1b1b;
	}

	.mbl__bemvindo{
		display: none;
	}
	.main__cima{
		color: #FFF;
		padding: 10px 0px;
		border-bottom: 1px solid #FFF;
		background-color: transparent;
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.container__infos{
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.main__baixo{
		padding: 20px 0px;
		background-color: transparent;
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.topo__mobile{
		display: none;
	}
	.main__baixo{
		.container{
			display: flex;
			justify-content: space-between;
		}
	}
	.segura__logo{
		margin-right: 40px;
	}
	.main__menu{
		display: flex;
		text-align: center;
		justify-content: space-between;
		flex-grow: 1;
		font-family: poppinsbold;
		font-size: 16px;
		line-height: 16px;
		padding-left: 0px;
		margin-bottom: 0px;
		.menu__item{
			list-style-type: none;
			display: flex;
			a{
				color: #FFF;
				padding: 0px 3px;
				display: flex;
				align-items: center;
				position: relative;
				&:before{
					content: '';
					position: absolute;
					background-color: transparent;
					width: 100%;
					height: 6px;
					top: 24px;
				}
				&:hover{
					text-decoration: none;
					display: flex;
					justify-content: center;
					align-items: center;
					&:before{
						background-color: #3b9b40;
						transition: all 1s linear;
					}
				}
			}
			&.menu__item--active{
				padding: 0px 3px;
				.menu__link{
					padding: 5px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #FFF;
					&:before{
						background-color: #3b9b40;
					}
				}
			}
		}
	}
	.topo__main{
		position: relative;
		z-index: 1000;
	}
	@include media-breakpoint-only(lg) {
		.segura__logo{
			max-width: 285px;
		}
	}
}
@include media-breakpoint-down(md){
	.segura__logo{
		max-width: 200px;
	}
	.main__menu{
		display: flex;
		text-align: center;
		justify-content: center;
		flex-direction: column;
		font-family: poppinslight;
		font-size: 15px;
		margin-top: 20px;
		padding: 0px;
		.menu__link{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #3b9b40;
			color: #FFF;
			padding: 10px;
			&:hover{
				color: #FFF;
                text-decoration: none;
                background-color: #1c3a19;
                transition: all 0.5s linear;
			}
		}
		.menu__item{
			list-style-type: none;
			text-align: center;
			justify-content: center;
			&.menu__item--active{
				.menu__link{
					background-color: #1c3a19;
				}
			}
		}
	}
	.topo__main{
		width: 250px;
		height: 100%;
		overflow-y: auto;
		position: fixed;
		background-color: #1b1b1b;
		left: -250px;
		z-index: 1080;
		transition: all 0.5s linear;
		display: flex;
		flex-direction: column;
		.container{
			padding: 0px;
		}
		.contato__box{
			margin-bottom: 5px;
		}
		.main__baixo{
			order: 1;
			.segura__redes{
				margin-left: auto;
				margin-right: auto;
				.redes__header{
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
		.main__cima{
			order: 2;
            .container{
				.segura__logo{
					padding: 10px;
					justify-content: center;
				}
				.infos__box{
					display: none;
				}
			}
		}
		&.shown{
			left: 0px;
		}
	}
	.topo__mobile{
		background-color: #1b1b1b;
		.container{
			padding-bottom: 10px;
			padding-top: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.mbl__toggler{
			background-color: transparent;
			border: none;
			color: #FFF;
			font-size: 30px;
		}
	}
}
