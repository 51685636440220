@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppinsbold/Poppins-Bold.eot');
    src: url('../fonts/poppinsbold/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppinsbold/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/poppinsbold/Poppins-Bold.woff') format('woff'),
        url('../fonts/poppinsbold/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/poppinsbold/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinslight';
    src: url('../fonts/poppinslight/Poppins-Light.eot');
    src: url('../fonts/poppinslight/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppinslight/Poppins-Light.woff2') format('woff2'),
        url('../fonts/poppinslight/Poppins-Light.woff') format('woff'),
        url('../fonts/poppinslight/Poppins-Light.ttf') format('truetype'),
        url('../fonts/poppinslight/Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinssemibold';
    src: url('../fonts/poppinssemibold/Poppins-SemiBold.eot');
    src: url('../fonts/poppinssemibold/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppinssemibold/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/poppinssemibold/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/poppinssemibold/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/poppinssemibold/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

