footer{
	.rodape__conteudo{
		background-color: #ededed;
		background-image: url(../images/background-rodape.jpg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		min-height: 350px;
		margin: 0 auto;
		padding-top: 85px;
		padding-bottom: 85px;
		justify-content: space-between;
		.box__segura__infos{
			display: flex;
			justify-content: space-between;
			@include media-breakpoint-down(md){
				flex-direction: column;
				align-items: center;
			}
			.segura__infos__principal{
				width: 100%;
				max-width: 350px;
				@include media-breakpoint-down(md){
					padding-bottom: 35px;
				}
				.segura__logo__footer{
					@include media-breakpoint-down(md){
						max-width: 245px;
						margin-left: auto;
						margin-right: auto;
						padding-bottom: 15px;
					}
				}
				.desc__footer{
					font-family: poppinslight;
					font-size: 14px;
					color: #FFF;
					line-height: 1.2;
					text-align: justify;
					padding-top: 10px;
				}
			}
			.segura__geral__infos{
				width: 100%;
				max-width: 350px;
				@include media-breakpoint-down(md){
					padding-bottom: 35px;
				}
				.segura__infos__footer{
					.box__contato__footer{
						display: flex;
						align-items: flex-start;
						@include media-breakpoint-down(md){
							justify-content: center;
						}
						.icone__contato__footer{
							display: flex;
							align-items: center;
							justify-content: center;
							width: 40px;
							height: 40px;
							border-radius: 50%;
							background-color: #3b9b40;
							color: #FFF;
						}
						.segura__contato__footer{
							padding-left: 10px;
							.contato__footer{
								display: flex;
								.contato__link__footer{
									font-family: poppinsbold;
									font-size: 14px;
									padding: 0;
									a{
										color: #FFF;
									}
								}
								.barrinha{
									color: #FFF;
								}
							}
							.titulo__contato__footer{
								font-family: poppinslight;
								font-size: 14px;
								color: #FFF;
							}
						}
					}
				}
				.box__endereco__footer{
					display: flex;
					align-items: flex-start;
					padding-top: 25px;
					@include media-breakpoint-down(md){
						justify-content: center;
					}
					.icone__endereco__footer{
						display: flex;
						align-items: center;
						justify-content: center;
						width: 40px;
						height: 40px;
						border-radius: 50%;
						background-color: #3b9b40;
						color: #FFF;
					}
					.segura__endereco__footer{
						padding-left: 10px;
						.segura__endereco__link{
							font-family: poppinsbold;
							font-size: 14px;
							color: #FFF;
						}
						.titulo__endereco__footer{
							font-family: poppinslight;
							font-size: 14px;
							color: #FFF;
						}
					}
				}

				.box__email__footer{
					display: flex;
					align-items: flex-start;
					padding-top: 20px;
					@include media-breakpoint-down(md){
						justify-content: center;
					}
					.icone__email__footer{
						display: flex;
						align-items: center;
						justify-content: center;
						width: 40px;
						height: 40px;
						border-radius: 50%;
						background-color: #3b9b40;
						color: #FFF;
					}
					.segura__email__footer{
						padding-left: 10px;
						.email__link__footer{
							font-family: poppinsbold;
							font-size: 14px;
							a{
								color: #FFF;
							}
						}
						.titulo__email__footer{
							font-family: poppinslight;
							font-size: 14px;
							color: #FFF;
						}
					}
				}
			}
			.segura__newsletter__footer{
				width: 100%;
				max-width: 350px;
				.titulo__newsletter__footer{
					font-family: poppinsbold;
					font-size: 16px;
					color: #FFF;
				}
				.desc__newsletter__footer{
					font-family: poppinslight;
					font-size: 14px;
					color: #FFF;
					padding-bottom: 15px;
				}

				.box__redes__footer{
					display: flex;
					align-items: center;
					@include media-breakpoint-down(md){
						align-items: center;
						justify-content: center;
					}
					.titulo__redes__footer{
						font-family: poppinsbold;
						font-size: 17px;
						color: #FFF;
						padding-right: 10px;
					}
					a{
						color: #FFF;
						text-decoration: none;
						font-size: 18px;
						padding-right: 10px;
					}
				}

				.contato__input{
					border: none;
					display: block;
					width: 100%;
					background-color: #ffffff;
					padding: 15px 10px;
					font-family: poppinslight;
					resize: none;
					border-radius: 40px;
					&:focus{
						outline: none;
					}

					&::placeholder{
						color: #777777;
					}
				}
				.form__contato{
					display: grid;
					max-width: 970px;
					margin-right: auto;
					margin-left: auto;
					@include media-breakpoint-down(md){
						display: block;
					}
					grid-template-columns: 1fr 1fr;
					column-gap: 10px;
					.form-group{
						margin-bottom: 10px;
					}
					.colspan{
						grid-column: span 2;
					}
					.segura__botao{
						background-color: #ffffff;
						padding-right: 20px;
						display: flex;
						align-items: flex-end;
						border-radius: 40px;
					}
					.enviar__botao{
						display: flex;
						align-items: center;
						justify-content: center;
						border: none;
						color: #3b9b40;
						background-color: transparent;
						font-size: 20px;
						padding-bottom: 17px;
					}
					.custom__check{
						position: fixed;
						left: 200vw;

						&:checked{
							& ~ .custom__label{
								&:after{
									opacity: 1;
								}
							}
						}
					}
					.custom__label{
						position: relative;
						padding-left: 25px;
						cursor: pointer;

						&::before{
							content: '';
							position: absolute;
							width: 15px;
							height: 15px;
							border-radius: 50%;
							background-color: #3b9b40;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
						}
						&::after{
							content: '';
							position: absolute;
							width: 9px;
							height: 9px;
							border-radius: 50%;
							background-color: #FFF;
							left: 3px;
							top: 50%;
							transform: translateY(-50%);
							opacity: 0;
							transition: all 0.3s linear;
						}
					}
					.segura__check{
						font-family: poppinslight;
						color: #FFF;
						a{
							color: #FFF;
						}
					}
				}
			}
		}
	}
	.rodape__creditos{
        background-color: #000000;
		color: #FFF;
		padding-bottom: 30px;
		text-align: center;
		font-family: poppinslight;
		display: flex;
		align-items: center;
        padding: 25px 0px 25px 0px;
		.container{
		    @include media-breakpoint-down(md){
				justify-content: center;
				align-items: center;
				display: flex;
				flex-direction: column;
			}
			.creditos{
				color: #FFF;
				font-size: 14px;
				text-align: center;
				font-family: poppinslight;
				text-align: center;
				span{
					font-family: poppinsbold;
				}
				svg{
					margin-left: 5px;
					color: #FFF;
				}
				@include media-breakpoint-down(md){
					text-align: center;
				}
			}
		}
	}
}
