#produtos-mod-3{

	.produto{
		max-width: 100%;
		width: 466px;
		text-align:center;

		a:hover{
			text-decoration: none;
			color: #000;

			.content:before,
			.content:after{
				opacity: 1;
			}
		}

		.content{
			position:relative;

			&:before,
			&:after{
				content:'';
				display: block;
				position:absolute;
				opacity: 0;
				transition: opacity 0.3s linear;
			}

			&:before{
				width: 100%;
				height:100%;
				background: rgba(#000,0.4);
				left:0;
				top:0;
				z-index:1;
			}

			&:after{
				left:16px;
				top:16px;

				width: calc(100% - 32px);
				height: calc(100% - 32px);

				border:10px solid #FFC84D;
				z-index: 2;
				background: url(assets/images/efeito-produto-modelo-3.png) center center no-repeat;
			}
		}

		.foto{
			margin:0;
		}

		.nome{
			@include gradient-y(#fff, rgba(#000,0.1));
			padding:10px;
		}

		.btn-area{
			padding: 10px;
		}

		.btn{
			background: transparent;
			color: #000;
			border-color:#000;
			border-radius:0;
			border-width:2px;

			&:hover{
				background: #000;
				color: $yellow;
			}
		}
	}

	@include media-breakpoint-up(lg){

		.row-produtos{
			margin-left: -10px;
			margin-right: -10px;

			.col-lg{
				max-width: 25%;
				padding-left:10px;
				padding-right:10px;
			}
		}
	}

	.paginacao{

		& > *{
			$color: #000;

			color: $color;


			&.active{
				background: $color;
				color: #fff;
				border-color: $color;
			}
		}
	}

	&.detalhes{
		
		padding-bottom: 60px;

		.page-header{
			margin-bottom: 30px;
		}
	}

	.box{
		padding: 20px;
		margin-botom:30px;

		&.box-white{
			background: #FFF;
		}
	}

	.subtitle-produtos{
		font-family: 'OFFISFB';
	}

	.midias-sociais{
		font-size: 20px;
		
		a{
			display: inline-block;
			margin: 0 0.5em;
		}
	}
}

#imagens-produto{
	.album{
		width:100%;
		text-align: center;

		img{
			display: inline-block;
			border:1px solid rgba(#000,0.2);
			border-radius: 4px;
		}

		display: flex;

		.owl-ctrls{
			display: inline-flex;
			padding:5px 10px;
			align-items:center;

			&:hover{
				text-decoration: none;
				color: #000;
				background: #ddd;
			}
		}

		.owl-carousel{
			margin:0 10px;
			flex-grow:1;
		}
	}

	@include media-breakpoint-up(lg){

		.image-g{
			margin-bottom: 15px;
		}
	}
}