#videos-3{

	h5{
		font-family: poppinsbold;
	}

	.page-item{
		.page-link{
			color: #3b9b40!important;
		}
	}

	.page-item.active{
		.page-link{
			background-color: #3b9b40!important;
			color: #FFF!important;
		}
	}


	.video-thumbnail{
		width: 555px;
		max-width: 100%;
		position: relative;

		.video-caption{
			position:absolute;
			bottom: 0;
			left: 0;
			width:100%;
			padding: 20px;
			z-index:2;
			@include gradient-y(transparent, rgba(#000,0.8));
			color: #FFF;
			text-shadow:0 0 2px rgba(#000,0.8);

			padding-right:50px;

			&:before{
				content: fa-content($fa-var-play);
				@extend .fas;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				font-size: 30px;
			}

			.tytpe{
				font-size: 12px;
			}

			.title{
				font-size: 25px;
			}
		}
	}

	.paginacao{
		display: flex;
		justify-content: center;
		margin-top: 40px;

		& > *{
			border-width: 1px;
			border-color: rgba(#000,0.2);
			background: #3b9b40!important;
			color: #FFF;
			margin:0;

			&.active,
			&:hover {
				background: darken(#3b9b40, 10%);
				text-decoration: none;
				color: #FFF;
			}
		}
	}
}
