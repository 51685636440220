.depoimentos#depoimento-mod-1{
	flex-grow:1;
	padding-bottom:100px;
	background-position: center top;

	p{
		font-family: bwregular;
	}

	.depoimento{
		background: #F6F6F7;
		border: 6px solid #fff;
		padding: 10px;
		text-align: center;
		margin:70px  10px 10px 10px;
		position: relative;
		padding-top:100px;
		box-shadow:0 0 8px rgba(#000,0.6);

		.avatar{
			width:140px;
			height:140px;
			border-radius: 100%;
			overflow:hidden;
			border:6px #FFF;
			position:absolute;
			top:-70px;
			left: calc(50% - 70px);
			border:6px solid #fff;
			box-shadow:0 0 8px rgba(#000,0.6);
		}
	}

    .form__depoimento{
        padding: 30px;
        background: #FFF;
        margin-top: 40px;
        box-shadow: 0 0 15px rgba(#000,.4);

        h2{
            text-align: center;
            padding-bottom: 10px;
            margin-bottom: 15px;
        }
        
        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: repeat(2, calc(50% - 15px));
            grid-column-gap: 30px;

            .grid-2{
                grid-column: span 2;
                font-family: bwbold;
            }
        }

        .form-control,
        .v-file-label{
            border-radius: 0
        }
        
        .btn-enviar{
            @extend .btn-lg, .btn-danger;
            border-radius: 0;
            font-family: bwbold;
            background-color: #00aca8;
            border-color: #00aca8;
        }
    }
}

#carouselDepoimento{
	.ctrls{
		text-align: center;
	}

	
	@include media-breakpoint-up(sm){
		.detalhe{
			position: absolute;
			top:30px;
			left:0;
			z-index:3;
		}

		.carousel-item{
			padding:0 50px;
		}
	}

	.controls{
		@extend .btn;
		
		margin: 10px;

		@include button-variant(#00aca8, #00aca8);
	}
}