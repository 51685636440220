#trabalhe-conosco-2{
    .ddd__telefone{
        display: grid;
        grid-template-columns: 90px calc(100% - 110px);
        grid-column-gap: 30px;
    }

    .form-control,
    .v-file-label{
        background: #e7e7e7;
        border-color: #e7e7e7;
        background-clip: unset;
        border-radius: 0;
    }

    textarea{
        min-height: 180px;
    }

    .v-file-button{
        width: 1rem;
        padding: 0 0;
        background: transparent;
        border-radius: none;
        overflow: hidden;
        white-space: nowrap;
        color: transparent;
        font-size: 0;

        &::before{
            content: fa-content($fa-var-upload);
            font-family: 'Font Awesome 5 Free';
            font-weight: bold;
            width: 1rem;
            height: 1rem;
            display: inline-block;
            font-size: 1rem;
            color: #000;
        }
    }

    .v-file-output{
        width: calc(100% - 1rem);
        padding-right: .75rem;
    }

    .btn-enviar{
        padding: .5rem .75rem;
        @include button-variant(#bf2d31, #bf2d31);
        border-radius: 0;
        width: 100%;
        max-width: 190px;
        font-weight: 900;
    }
}