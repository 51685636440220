.galeria-fotos#modelo-3{
	padding-bottom: 100px;
	.paginacao{
	    display: flex;
	    justify-content: center;
	}

	.album-fotografia{
		width: 350px;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 30px;
		position: relative;

		.album-caption{
			position: absolute;
			padding:20px;
			width: 100%;
			height: 100%;
			background: rgba(#000, 0.68);
			left:0;
			top: 0;
			display: flex;
			flex-direction: column;
			justify-content:center;
			text-align: center;
			opacity: 0;
			transition: opacity 0.3s linear;

			&:before{
				content:'';
				width: calc(100% - 20px);
				height: calc(100% - 20px);
				position: absolute;
				border:1px solid #fff;
				left: 10px;
				top: 10px;
			}
		}

		.nome{
			color: #FFF;
		}

		.description{
			color: #00aca8;
		}

		&:hover{
			.album-caption{
				opacity: 1;
			}
		}
	}
}