.banner__carousel{
	max-width: 1920px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;

	.carousel-inner{
		max-width: 1920px;
		width: 100%;
		justify-content: center;
	}

	@include media-breakpoint-up(lg){
		margin-top: -211px;
	}
    .carousel-indicators{
		bottom: 116px;
		@include media-breakpoint-down(md){
			bottom: 0;
		}
		li{
			text-indent: 0;
			top: 0;
			border-bottom: 0;
			border-top: 0;
			width: 18px;
			height: 18px;
			opacity: 1;
			background-color: #FFF;
			border-radius: 50%;
			position: relative;
			margin: 0px 5px;
			&:before{
				content: '';
				position: absolute;
				width: 18px;
				height: 18px;
				top: 50%;
				left: 50%;
				border: 2px solid #FFF;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				opacity: 0;
			}

			&.active{
				background-color: transparent;
				&:before{
					opacity: 1;
				}
			}
		}
	}
}

.etica{
	background-color: #ededed;
	background-image: url(../images/background-etica.jpg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 1219px;
    margin: 0 auto;
	@include media-breakpoint-down(md){
		background-image: none;
		background-color: #FFF;
		padding-bottom: 30px;
		padding-top: 40px;
	}
	.infos__contato{
		position: absolute;
		transform: translate(-50%, -105px);
		width: 1110px;
		max-width: 90%;
		display: flex;
		align-items: center;
		justify-content: center;
		left: 50%;
		background-color: #1b1b1b;
		z-index: 999;
		@include media-breakpoint-down(md){
			margin-left: auto;
			margin-right: auto;
			position: relative;
			left: unset;
			transform: unset;
			border-bottom: 20px;
		}
		.box__contato{
			display: flex;
			align-items: center;
			justify-content: center;
			@include media-breakpoint-down(md){
				flex-direction: column;
			}
			.contato__card__diferente{
				&::after{
					content: "";
					position: absolute;
					left: calc(50% - 150px);
					top: calc(50% - 20px);
					border-top: 20px solid transparent;
					border-bottom: 20px solid transparent;
					border-left: 20px solid #1b1b1b;
					@include media-breakpoint-down(md){
						display: none;
					}
				}
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #1b1b1b;
				padding: 30px;
				.titulo__card__diferente{
					font-family: poppinsbold;
					font-size: 25px;
					color: #FFF;
					line-height: 1.2;
				}
			}
			.contato__card{
				align-items: center;
				background-color: #FFF;
				padding: 30px;
				.segura__icone__contato{
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #1b1b1b;
					width: 90px;
					height: 90px;
					border-radius: 50%;
					border: 6px solid #8d8d8d;
					margin-left: auto;
					margin-right: auto;
				}
				.segura__infos__card{
					padding-top: 15px;
					.titulo__card__contato{
						font-family: poppinsbold;
						font-size: 18px;
						color: #1b1b1b;
						line-height: 1.2;
						text-align: center;
						text-transform: uppercase;
					}
					.desc__card__contato{
						font-family: poppinslight;
						font-size: 16px;
						color: #777777;
						line-height: 1.3;
						text-align: center;
						padding-bottom: 15px;
					}
					.btn__card__contato{
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #1b1b1b;
						width: 150px;
						height: 50px;
						border-radius: 50px;
						font-family: poppinsbold;
						font-size: 16px;
						color: #FFF;
						margin-left: auto;
						margin-right: auto;
						text-decoration: none;
						&:hover{
							background-color: #3b9b40;
							transition: all 0.5s linear;
						}
					}
				}
			}
		}
	}
	.col__etica{
		padding-top: 446px;
		padding-left: 62px;
		@include media-breakpoint-down(md){
			padding: 0;
		}
		.titulo__etica{
			font-family: poppinsbold;
			font-size: 40px;
			color: #1b1b1b;
			line-height: 1.2;
			padding-bottom: 15px;
			@include media-breakpoint-down(md){
				font-size: 30px;
			}
		}
		.desc__etica{
			font-family: poppinslight;
			font-size: 16px;
			color: 777777;
			line-height: 1.3;
			text-align: justify;
			padding-bottom: 35px;
		}
		.btn__etica{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 220px;
			height: 70px;
			border-radius: 50px;
			background-color: #3b9b40;
			font-family: poppinsbold;
			font-size: 16px;
			color: #FFF;
			text-decoration: none;
		}
	}
}

.noticias__home{
	background-color: #e5e5e5;
	padding-top: 118px;
	padding-bottom: 118px;
	.titulo__noticias{
		font-family: poppinsbold;
		font-size: 40px;
		color: #1b1b1b;
		text-align: center;
		padding-bottom: 40px;
		@include media-breakpoint-down(md){
			font-size: 30px;
		}
	}
	.col__cards{
		padding-bottom: 40px;
		margin-left: auto;
		margin-right: auto;
		.card__noticia__principal{
			text-decoration: none;
			.segura__card__noticia{
				width: 100%;
				height: 100%;
				max-width: 540px;
				max-height: 520px;
				background-color: #FFF;
				text-decoration: none;
				@include media-breakpoint-down(md){
					padding-bottom: 20px;
				}
				.card__noticia__body{
					padding: 43px 25px;
					border-top: 7px solid #1b1b1b;
					.titulo__card__noticia{
						font-family: poppinsbold;
						font-size: 18px;
						color: #1b1b1b;
						line-height: 1.2;
						text-align: justify;
					}
					.segura__data__noticia{
						display: flex;
						align-items: center;
						padding-top: 10px;
						.nome__autor{
							font-family: poppinslight;
							font-size: 15px;
							color: #777777;
						}
						.barrinha{
							color: #777777;
						}
						.data__noticia{
							font-family: poppinslight;
							font-size: 15px;
							color: #777777;
						}
					}
				}
			}
		}
		.segura__cards__noticia{
			@include media-breakpoint-down(md){
				padding-top: 30px;
			}
			.card__noticia__small{
				width: 100%;
				height: 100%;
				max-width: 540px;
				max-height: 160px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;
				background-color: #FFF;
				text-decoration: none;
				@include media-breakpoint-down(md){
					flex-direction: column;
				}
				.card__header__small{
					border-right: 7px solid #1b1b1b;
					@include media-breakpoint-down(md){
						display: none;
					}
				}
				.card__body__small{
					width: 100%;
					height: 100%;
					max-width: 365px;
					max-height: 160px;
					padding: 30px 20px;
					.titulo__card__noticia{
						font-family: poppinsbold;
						font-size: 18px;
						color: #1b1b1b;
						line-height: 1.2;
						text-align: justify;
						--linhas: 2;
					}
					.segura__data__noticia{
						display: flex;
						align-items: center;
						padding-top: 10px;
						.nome__autor{
							font-family: poppinslight;
							font-size: 15px;
							color: #777777;
						}
						.barrinha{
							color: #777777;
						}
						.data__noticia{
							font-family: poppinslight;
							font-size: 15px;
							color: #777777;
						}
					}
				}
			}
		}
	}
	.btn__noticias{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 220px;
		height: 70px;
		border-radius: 50px;
		background-color: #1b1b1b;
		font-family: poppinsbold;
		font-size: 16px;
		color: #FFF;
		text-decoration: none;
		margin-left: auto;
		margin-right: auto;
	}
}

.politica__de__privacidade{
	.container__politica__de__privacidade {
		padding-top: 100px;
		padding-bottom: 100px;
		.politica__titulo {
			font-family: poppinsbold;
			font-size: 40px;
			text-align: left;
			line-height: 44px;
			position: relative;
			margin-bottom: 20px;
			color: #1b1b1b;
		}
		.content__editable {
			font-family: poppinslight!important;
			color: #777777;
			padding-bottom: 30px;
		}

		.text-center {
			padding-bottom: 20px;
		}

		.btn-voltar {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 220px;
			height: 70px;
			border-radius: 50px;
			background-color: #1b1b1b;
			font-family: poppinsbold;
			font-size: 16px;
			color: #FFF;
			text-decoration: none;
			margin-left: auto;
			margin-right: auto;
			border: none;
		}
	}
}

.termos__de__uso{
	.container__termos__de__uso {
		padding-top: 100px;
		padding-bottom: 100px;
		.termos__titulo {
			font-family: poppinsbold;
			font-size: 40px;
			text-align: left;
			line-height: 44px;
			position: relative;
			margin-bottom: 20px;
			color: #1b1b1b;
		}

		.content__editable {
			font-family: poppinslight!important;
			color: #777777;
			padding-bottom: 30px;
		}

		.text-center {
			padding-bottom: 20px;
		}

		.btn-voltar {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 220px;
			height: 70px;
			border-radius: 50px;
			background-color: #1b1b1b;
			font-family: poppinsbold;
			font-size: 16px;
			color: #FFF;
			text-decoration: none;
			margin-left: auto;
			margin-right: auto;
			border: none;
		}
	}
}
