.contato-page#contato3{
	padding-bottom: 80px;

	p{
		font-family: poppinslight;
	}

	.text-muted{
		font-family: poppinsbold;
	}

	.title-ordenal{
		padding-left:3em;
		position: relative;
		font-family: poppinsbold;

		.order{
			display: block;
			font-size: 16px;
			color: #3b9b40;
		}

		&:before{
			content: '';
			display: block;
			width: 2em;
			height:0.2em;
			background: currentcolor;
			position: absolute;
			left: 0;
			top: calc(16px * 1.4 + calc(1em * 1.4 * 0.4));
			transform: translateY(-50%);
		}
	}

	form{
		border-top:5px solid #000;
		padding-top:40px;
		margin-bottom:60px;
	}

    .form-group{
		position: relative;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-bottom: 0;

		.form-control{
			border: none;
            border-radius: 0;
            background-color: transparent;
            background-clip: unset;
            background-image: linear-gradient(
                to right,
                $success 0,
                $success 50%,
                rgba(#000,0.3) 50%,
                rgba(#000,0.3) 100%
            );
            background-size: 200% 3px;
            background-position: right bottom;
            background-repeat: no-repeat;
            transition: all .3s linear;

			&::placeholder{
				color: transparent;
			}

            &:focus{
                box-shadow: none;
            }
		}

        .label{
            font-size: 1rem;
            top: 1.85rem;
            left: .75rem;
            font-size: 1rem;
            position: absolute;
            transition: all .3s linear;
			font-family: poppinslight;
        }

        &.focus {
            .label{
                top: 0;
            }
        }

        &.focus .form-control,
        .form-control:focus{
            background-position: left bottom;
        }

        $states: (
            'success': $success,
            'warning': $orange,
            'error'  : $danger
        );

        .message__validation{
            padding: 10px 15px;
            background: #DDD;
            color: #000;
            border-radius: 7px;
            margin-top: 10px;
            position: relative;
            animation: rubberBand 1s linear;

            &::before{
                content:'';
                position: absolute;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #DDD;
                left: 50%;
                bottom: calc(100% - 1px);
            }
        }

        @each $state, $cor in $states {
            &.has__#{$state} {
                .label{
                    color: $cor;
                }

                .form-control{
                    background-image: linear-gradient(
                        to right,
                        $cor 0,
                        $cor 50%,
                        rgba(#000,0.3) 50%,
                        rgba(#000,0.3) 100%
                    );
                }

                .message__validation{
                    background: $cor;
                    color: color-contrast($cor);

                    &::before{
                        border-bottom-color: $cor;
                    }
                }
            }
        }

        textarea.form-control{
            min-height: 151px;
            resize: none;
        }

	}

	.btn-enviar{
		font-family: poppinsbold;
		display: inline-flex;
		align-items:center;
		border-radius:0;

		@include button-variant(#FFF, #FFF);

		&:before{
			content:'';
			width:1.5em;
			height: 0.25em;
			background: currentcolor;
			display: inline-block;
			margin-right: 7px;
		}
	}

	.dados-contato{
		font-family: poppinslight;
		a{
			color: inherit;

			&:hover{
				color: #FFBF00;
			}
		}
	}
	i{
		font-size: 20px;
	}
	.media-body{
		padding-left: 10px;
	}
}
