.orcamentos#orcamento-mod-1{

	.titles{
		font-family: montbold!important;

		svg{
			color: $red;
		}
	}

	.md-form{
		& > label,
		.form-control{
			color: #777;
		}

		.form-control{
			border-radius:0;

			@include media-breakpoint-up(md){
				padding: 0.35rem 1rem;
				font-size: 1.25rem;
				line-height:1.5;
			}
		}

		@include media-breakpoint-up(md){
			.select-custom ~ label{
				padding: 0.35rem 1rem;
				font-size:1rem;
			}
		}

		.btn{
			border-radius:0;
			@include media-breakpoint-up(md){
				padding: 0.35rem 1rem;
			}
		}

		.input-group{
			.form-control{
				border-right-color: transparent;
			}

			.btn{
				border-top-color: rgba(#000,0.2);
				border-right-color: rgba(#000,0.2);
				border-bottom-color: rgba(#000,0.2);
				margin:0;
			}
		}

		&.active {
			& > label,
			.form-control-lg ~ label,
			.form-control-sm ~ label{
				top:0;
				padding-top:0;
				font-size: 0.8rem;
			}
		}
	}
}
