#videos-2{

	.titles{
		overflow: hidden;
		margin-bottom: 60px;

		h2 {
			padding-left: 15px;
			border-left:3px solid #AE001D;

			span{
				display: inline-block;
				position: relative;

				&:before{
					content:'';
					width: 100vw;
					border-top:1px solid rgba(#000,.2);
					position: absolute;
					left: calc(100% + 15px);
					top:50%;
				}
			}
		}

		p{
			color: #777;
			padding-left: 18px;
		}
	}

	.card-video{
		text-align:center;

		.card-title{
			font-weight: bold;
			font-size: 17px;

			&:after{
				content: '';
				display: block;
				width: 60%;
				border:1px solid rgba(#000,0.1);
				margin:15px auto;
			}
		}

		.btn-card{
			background: #C5002B;
			color: #FFF;
			margin-top: 15px;
			padding-left: 20px;
			padding-right: 20px;

			&:hover{
				background: #2D2D2D;
				color: #FFF;
			}
		}
	}

	.paginacao{
		display: flex;
		justify-content: center;
		margin-top: 40px;

		& > *{
			border-width: 1px;
			border-color: rgba(#000,0.2);
			background: #D40030;
			color: #FFF;
			margin:0;

			&.active,
			&:hover {
				background: darken(#D40030, 10%);
				text-decoration: none;
				color: #FFF;
			}
		}
	}
}