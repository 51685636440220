#produtos-mod-5{
	color: #777;

	.subtitle-produtos{
		font-weight: bold;
		font-size: 24px;
		color: #000;

		svg{
			color: $red;
			margin:0;
			vertical-align: middle;
		}
	}

	.row-produtos{
		& > *{
			display: flex;
		}
	}

	.produto{
		width: 100%;
		max-width: 350px;

		margin-left: auto;
		margin-right: auto;

		display: flex;
		flex-direction: column;

		.foto{
			margin-bottom: 0;
		}

		.nome{
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			padding:10px;
		}

		.descricao{
			// flex-grow: 1;
			color: #777;
			padding-bottom:10px;
		}

		.btn{
			$bg: #D9002E;

			border-radius: 0;
			background: $bg;
			color: #FFF;

			&:hover{
				background: darken($bg, 10%);
				color:#FFF;
			}
		}
	}

	.paginacao{

		& > *{
			$color: #D9002E;

			color: $color;


			&.active{
				background: $color;
				color: #fff;
				border-color: $color;
			}
		}
	}

	#carousel{
		max-width: 460px;
		margin-left:auto;
		margin-right:auto;

		figure{
			margin-bottom:0;
		}

		.controls{
			$bg: #A80330;
			position:absolute;
			bottom:0;
			right:0;
			background: $bg;
			display: flex;
			font-size: 20px;

			&:before{
				content:'';
				width:25px;
				height:100%;
				position:absolute;
				right:calc(100% - 4px);
				top:0;
				background: $bg;
				clip-path: polygon(0 100%, 20px 0, 100% 0, 100% 100%);
			}

			a{
				display: block;
				padding:10px;
				color: #FFF;

				&:hover{
					color: $yellow;
					background: rgba(#000,.2);
					text-decoration: none;
				}
			}
		}
	}
    .bar__categorias{
        box-shadow: 0 0 10px rgba(#000,.4);
        margin-bottom: 40px;
        position: sticky;
        top: 15px;
    
        .bar__categorias__header{
            padding: 15px;
            text-align: center;
            font-weight: 900;
            font-size: 18px;
            background: #9D192B;
            color: #FFF;
        }
    
        ul{
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
        }
    
        a{
            display: block;
            padding: 10px 15px;
            flex-grow: 1;
        }
    
        .btn{
            padding: 10px 15px;
            border-radius: 0;
            box-shadow: none !important;

            .fas{
                transform: rotate(var(--rotate, 0deg));
                transition: transform .3s linear;
            }

            &[aria-expanded="true"] {
                --rotate: -180deg;
            }
        }
    }
    
    .categoria__header{
        display: flex;
    }

    .categoria + .categoria{
        border-top: 1px solid #CCC;
    }

    .subcategorias{
        border-top: 1px solid #CCCC;
        background: #DDD;

        li + li{
            border-top: 1px solid rgba(#000,.1);
        }
    }
}