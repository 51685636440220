
#produtos-mod-4{
    padding-bottom: 100px;
    
	.bg-capa{
		padding: 60px 0 90px 0;
		color: #FFF;
		background-position:center top;
		background-size: cover;
	}

	.title{
		padding-left: 1em;
		border-left:3px solid currentcolor;
	}


	.bg-F6F6F6{
		border-top:1px solid rgba(#000,0.1);
		padding: 60px 0;
	}


    .produtos-landscape{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 40px;

        .produto-landscape{
            position: relative;
            overflow: hidden;
            background: #FFF;

            img{
                transition: all 0.3s linear;
            }

            .caption{
                position:absolute;
                bottom:0;
                left: 0;
                width: 100%;
                display: flex;
                background: #232222;
                transition: transform 0.3s linear;

                .nome{
                    flex-grow:1;
                    color: #828282;
                    font-family: bwregular;
                }

                .nome,
                .fa{
                    padding:10px;
                }

                .fa{
                    background: #414141;
                    color: #232222;
                    display: flex;
                    align-items:center;
                }
            }
            
        }
        @include media-breakpoint-up(xl){
            .produto-landscape{
                width:25%;

                &:hover{
                    .caption{
                        transform: translateY(0);
                    }

                    img{
                        transform: scale(1.05);
                        opacity:0.7
                    }
                }
            }

            .caption{
                transform: translateY(100%);
            }

        }
        
        @include media-breakpoint-between(sm, lg){
            justify-content: center;
            
            .produto-landscape{
                max-width:50%;
            }
        }
    }

    .album-produtos{
        .album{
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            margin-left:-7.5px;
            margin-right:-7.5px;

            .thumbnail{
                padding: 0 7.5px;
                margin-bottom:15px;
                width: 25%;
            }
        }
    }

	.breadcrumb-area{
		background: #FFF;
		text-align: center;
		color: #004274;
	}

	.mala-area{
		position:relative;
		top: -75px;
		z-index: 3;
		margin-bottom: -50px;
	}

	.breadcrumb{
		justify-content: center;
		color: #777;
		background: none;
		border-radius: 0;

		li + li:before{
			content: '/';
			display: inline-block;
			margin:0 10px;
		}
	}

	.album-produtos{
		max-width: 540px;
		margin-left: auto;
		margin-right: auto;
	}
    
    .container__produtos{
        width: 100%;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 15px;
    
        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: 250px calc(100% - 280px);
            grid-column-gap: 30px;
    
            .bar__categorias{
                align-self: flex-start;
                position: sticky;
                top: 15px;
            }
        }
    }
    
    .bar__categorias{
        box-shadow: 0 0 10px rgba(#000,.4);
        margin-bottom: 40px;
    
        .bar__categorias__header{
            padding: 15px;
            text-align: center;
            font-weight: 900;
            font-size: 18px;
            background: #00aca8;
            color: #FFF;
            font-family: bwbold;
        }
    
        ul{
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
        }
    
        a{
            display: block;
            padding: 10px 15px;
            flex-grow: 1;
            font-family: bwregular;
        }
    
        .btn{
            padding: 10px 15px;
            border-radius: 0;
            box-shadow: none !important;

            .fas{
                transform: rotate(var(--rotate, 0deg));
                transition: transform .3s linear;
            }

            &[aria-expanded="true"] {
                --rotate: -180deg;
            }
        }
    }
    
    .categoria__header{
        display: flex;
    }

    .categoria + .categoria{
        border-top: 1px solid #CCC;
    }

    .subcategorias{
        border-top: 1px solid #CCCC;
        background: #DDD;

        li + li{
            border-top: 1px solid rgba(#000,.1);
        }
    }
    
    .paginacao{

        & > *{
            $color: #000;

            color: $color;


            &.active{
                background: $color;
                color: #fff;
                border-color: $color;
            }
        }
    }
}
