#produtos-mod-6{
	padding-bottom: 100px;
	.produto{
		max-width: 100%;
		width: 466px;
		text-align:center;

		a:hover{
			text-decoration: none;
			color: #000;

			.content:before,
			.content:after{
				opacity: 1;
			}
		}

		.content{
			position:relative;

			&:before,
			&:after{
				content:'';
				display: block;
				position:absolute;
				opacity: 0;
				transition: opacity 0.3s linear;
			}

			&:before{
				width: 100%;
				height:100%;
				background: rgba(#000,0.4);
				left:0;
				top:0;
				z-index:1;
			}

			&:after{
				left:16px;
				top:16px;

				width: calc(100% - 32px);
				height: calc(100% - 32px);

				border:10px solid #FFC84D;
				z-index: 2;
				background: url(assets/images/efeito-produto-modelo-3.png) center center no-repeat;
			}
		}

		.foto{
			margin:0;
		}

		.nome{
			@include gradient-y(#fff, rgba(#000,0.1));
			padding:10px;
			font-family: bwextrabold;
		}

		.btn-area{
			padding: 10px;
			font-family: bwregular;
		}

		.btn{
			background: transparent;
			color: #000;
			border-color:#000;
			border-radius:0;
			border-width:2px;

			&:hover{
				background: #00aca8;
				color: #FFF;
			}
		}
	}

	@include media-breakpoint-up(lg){

		.row-produtos{
			margin-left: -10px;
			margin-right: -10px;

			.col-lg{
				max-width: 33.333333%;
				padding-left:10px;
				padding-right:10px;
			}
		}
	}

	@include media-breakpoint-between(sm,md){

		.row-produtos{
			margin-left: -10px;
			margin-right: -10px;

			.col-lg{
				max-width: 50%;
				padding-left:10px;
				padding-right:10px;
			}
		}
	}

	.paginacao{

		& > *{
			$color: #000;

			color: $color;


			&.active{
				background: $color;
				color: #fff;
				border-color: $color;
			}
		}
	}

	&.detalhes{
		
		justify-content: center;
        display: flex;
        margin-bottom: 40px;

		.page-header{
			border-color: #9D192B;
            background: #9D192B;
            color: #FFF;
		}
	}

	.box{
		padding: 20px;
		margin-botom:30px;

		&.box-white{
			background: #FFF;
		}
	}

	.subtitle-produtos{
		font-family: bwextrabold;
	}

	p{
		font-family: bwregular;
	}

	.midias-sociais{
		font-size: 20px;
		
		a{
			display: inline-block;
			margin: 0 0.5em;
		}
	}

    .bar__categorias{
        box-shadow: 0 0 10px rgba(#000,.4);
        margin-bottom: 40px;
        position: sticky;
        top: 15px;
    
        .bar__categorias__header{
            padding: 15px;
            text-align: center;
            font-weight: 900;
            font-size: 18px;
            background: #00aca8;
            color: #FFF;
            font-family: bwbold;
        }
    
        ul{
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
        }
    
        a{
            display: block;
            padding: 10px 15px;
            flex-grow: 1;
            font-family: bwregular;
        }
    
        .btn{
            padding: 10px 15px;
            border-radius: 0;
            box-shadow: none !important;

            .fas{
                transform: rotate(var(--rotate, 0deg));
                transition: transform .3s linear;
            }

            &[aria-expanded="true"] {
                --rotate: -180deg;
            }
        }
    }
    
    .categoria__header{
        display: flex;
    }

    .categoria + .categoria{
        border-top: 1px solid #CCC;
    }

    .subcategorias{
        border-top: 1px solid #CCCC;
        background: #DDD;

        li + li{
            border-top: 1px solid rgba(#000,.1);
        }
    }
}

#produto-carrosel{
	.carousel-controls{
		position: absolute;
		bottom: -15px;
		right: 50%;
		left: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		.carousel-control-prev,
		.carousel-control-next{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background-color: #00aca8;
			color: #FFF;
			margin: 5px;
			opacity: 1;

			&:hover{
				text-decoration: none;
				animation: rubberBand 1s linear;
			}
		}
	}
}