.page-header{
    background: map-get($cores, cor-secundaria )!important;
    padding-top:40px;
    padding-bottom:40px;
    text-align: center;
    margin-bottom: 60px;

    .breadcrumb{
        padding:0 30px 15px 30px;
        min-width: 250px;
        max-width: 100%;
        background: none;
        display: inline-flex;
        justify-content:center;
        margin-bottom: 0;
        border-radius:0;
        border-bottom: 2px solid #fff!important;
        position: relative;



        a{
            color: #fff;
            font-family: poppinslight !important;
        }


        span{
            color: map-get($cores, cor-primaria )!important;
            font-family: poppinslight !important;
            cursor: default;
        }

        &:before{
            content:'';
            position: absolute;
            left:50%;
            bottom:-2px;
            transform: translateX(-50%);
            height: 7px;
            width: 60px;
            background: map-get($cores, cor-primaria )!important;
        }

        li + li:before{
            content:'/';
            display: inline-block;
            margin:0 10px;
            color: #fff;
        }
    }

     h1{
        font-family: poppinsbold !important;
        font-size: 40px;
        color: #fff;
    }

    .detail{
        border: 1px solid currentcolor;
        border-top: none !important;
    }

    .subtitle{
        font-size: 20px;
        // margin-bottom:5px;
    }

    .detail-title{
        width: 40px;
        height:3px;
        position: relative;
        font-size: 20px;


        &:before,
        &:after{
            content:'';
            display:block;
            position:absolute;
            width: calc(50% - 2px);
            height:100%;

        }

        &:before{
            left:0;
            background: #FFF;
        }

        &:after{
            right:0;
            background: map-get($cores, cor-primaria );
        }
    }

}

svg.detail-titles{
    display:inline-block;
    vertical-align: middle;
    border:none;

    use{
        fill: currentcolor;
    }
}
