.depoimentos#depoimento-mod-2{
    padding-bottom: 60px;

	.depoimento{
		@include clearfix;

		.avatar{
			float: left;
			width: 80px;
			height: 80px;
			border-radius: 100%;
			margin-right: 10px;
			margin-bottom: 10px;
			overflow: hidden;
			box-shadow: 0 0 8px #000;
			border:5px solid #fff;
		}

		color: #989898;
		font-size: 14px;

		a{
			color: #9D192B;
		}

		.title{
			font-size: 16px;
			color: #000;
			margin-bottom:15px;

			.empresa{
				font-size:12px;
			}
		}

	}
    
    .form__depoimento{
        padding: 30px;
        background: #FFF;
        margin-top: 40px;
        box-shadow: 0 0 10px rgba(#000,.4);

        h2{
            text-align: center;
            padding-bottom: 10px;
            margin-bottom: 15px;
        }
        
        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: repeat(3, calc( (100% - 60px) / 3 ));
            grid-column-gap: 30px;

            .grid-2{
                grid-column: span 3;
            }
        }

        .form-control,
        .v-file-label{
            border-radius: 0
        }
        
        .btn-enviar{
            @extend .btn-primary;
            border-radius: 0;
            padding: .75rem 2rem;
            min-width: 190px;
        }
    }
}