#clientes-e-parceiros-mod-3{
	padding-bottom: 50px;
	.cliente{
		padding-bottom:30px;
		margin-bottom:30px;
		border-bottom: 12px solid rgba(#000,0.1);

		figure{
			margin-bottom:10px;
		}

		img{
			border:1px solid rgba(#000,0.2);
			background:#FFF;
			padding:10px;
		}

		p{
			font-family: bwregular;
		}

		.nome{
			text-align: left;
			margin-top:0;
			overflow:hidden;
			margin-bottom:15px;
			font-family: bwbold;

			span{
				display: inline-block;
				position: relative;

				&:before{
					content:'';
					display:block;
					border:1px solid rgba(#000,.2);
					left:100%;
					margin-left:30px;
					top:calc(50% - 1px);
					width:100vw;
					position:absolute;
				}
			}

			&:after{
				content:'';
				background: currentcolor;
				opacity:0.9;
				display:block;
				width:2em;
				height:4px;
				margin-top:5px;
			}
		}
	}

	.paginacao{
		display: flex;
		justify-content: center;
		& > *{
			$color: #000;

			color: $color;


			&.active{
				background: $color;
				color: #fff;
				border-color: $color;
			}
		}
	}
}