.contato-page#contato1{
	background-position: center top;


	#mapa-contato{
		padding-bottom: percentage(472/1920);
	}

	.pdt-70{
		padding-top: 70px;
	}

	p{
		font-family: montregular!important;
	}

	.md-form{
		.form-control{
			border-top:none;
			border-right:none;
			border-left:none;
			border-bottom-width:3px;
			border-color: #000;
		}

		.hover-eff{
			position: absolute;
			left: 0;
			bottom:1rem;
			z-index: 2;
			height:3px;
		}
	}

    .form-group{
        position: relative;
        padding-top: 1.5rem;
        padding-bottom: 1rem;

        .label{
            position: absolute;
            left: .75rem;
            top: calc(1.875rem + 1px);
            font-size: 1rem;
            transition: all .3s linear;
        }

        .form-control{
            border: none;
            border-radius: 0;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: 200% 3px;
            background-image:
                linear-gradient(
                    to right,
                    $success 0,
                    $success 50%,
                    #000 50%
                )
            ;
            transition: background .3s linear;

            &::placeholder {
                color: transparent;
            }
        }

        &.focus .form-control,
        .form-control:focus,
        .form-control:not(:placeholder-shown) {
            background-position: left bottom;
            box-shadow: none;
        }

        &.focus,
        &.has__succcess,
        &.has__error,
        &.has__warning {
            .label{
                top: 0;
                font-size: 12px;
            }
        }

        $states: (
            'success': $success,
            'warning': $orange,
            'error'  : $danger,
            'danger' : $danger,
        );

        @each $state, $color in $states {
            &.has__#{$state} {
                .form-control{
                    background-image:
                        linear-gradient(
                            to right,
                            $color 0,
                            $color 50%,
                            #000 50%
                        )
                    ;
                    background-position: left bottom;
                }

                .message__validation,
                .label{
                    color: $color;
                }
            }
        }

        textarea.form-control{
            min-height: 8.25rem;
        }
    }

	.btn-enviar{
		background: #000;
		color: #FFF;
		padding-left:1.5rem;
		padding-right:1.5rem;
		border-radius: 2rem;

		&:hover{
			background: lighten(#000,20%);
			color: #FFF;
		}
	}

	.media-contato{
		padding: 30px 0;
		border-bottom:2px dashed rgba(#000,0.2);
		border-top:2px dashed rgba(#000,0.2);

		.media-body{
			align-self: center;
		}

		.icon{
			margin-right: 15px;
			align-self: center;
		}
	}
}
